import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import React from 'react'

function MissingRecord(props: SvgIconProps) {
  return (
    <SvgIcon
      height="120"
      viewBox="0 0 150 120"
      width="150"
      {...props}
      style={{ height: 60, width: 60, ...props.style }}
    >
      <defs>
        <linearGradient
          id="missingRecord-a"
          x1="68.468%"
          x2="30.414%"
          y1="3.458%"
          y2="84.282%"
        >
          <stop offset="0%" stopColor="#FFF" />
          <stop offset="100%" stopColor="#F4F6FD" />
        </linearGradient>

        <linearGradient
          id="missingRecord-b"
          x1="29.561%"
          x2="72.575%"
          y1="-.358%"
          y2="105.635%"
        >
          <stop offset="0%" stopColor="#EFF4FB" />
          <stop offset="100%" stopColor="#BACAE9" />
        </linearGradient>

        <linearGradient
          id="missingRecord-c"
          x1="40.5%"
          x2="67.189%"
          y1="17.196%"
          y2="109.463%"
        >
          <stop offset="0%" stopColor="#EFF4FB" />
          <stop offset="100%" stopColor="#BACAE9" />
        </linearGradient>

        <linearGradient
          id="missingRecord-d"
          x1="2.221%"
          y1="47.921%"
          y2="19.343%"
        >
          <stop offset="0%" stopColor="#EFF4FC" />
          <stop offset="100%" stopColor="#BBCBEA" />
        </linearGradient>

        <linearGradient
          id="missingRecord-e"
          x1="17.14%"
          x2="92.9%"
          y1="17.141%"
          y2="92.9%"
        >
          <stop offset="0%" stopColor="#EFF4FB" />
          <stop offset="100%" stopColor="#BACAE9" />
        </linearGradient>

        <linearGradient
          id="missingRecord-f"
          x1="14.645%"
          x2="85.355%"
          y1="14.644%"
          y2="85.355%"
        >
          <stop offset="0%" stopColor="#EFF4FB" />
          <stop offset="100%" stopColor="#BACAE9" />
        </linearGradient>
      </defs>

      <g fill="none" fillRule="evenodd">
        <path
          d="M33.287.296C16.267.331 7.612 16.48 4.62 23.06 2.731 27.213.822 33.02.814 39.495v.082c.014 10.48 5.02 22.698 23.14 32.486 38.246 13.752 29.587 35.861 60.82 45.456a33.402 33.402 0 0 0 9.791 1.472h.108c21.857-.035 43.123-20.848 51.571-42.871 6.252-16.295 3.808-48.567-19.923-60.628-13.495-6.86-22.615-4.627-32-2.394-10.796 2.568-21.942 5.137-40.501-6.14C45.973 2.192 39.2.309 33.396.297h-.109z"
          fill="url(#missingRecord-a)"
          transform="translate(0 .062)"
        />
        <path
          d="M63.402.045l.02 93.448-63.241.014v-78.68L14.932.055z"
          fill="url(#missingRecord-b)"
          transform="translate(44.388 2.17)"
        />
        <path
          d="M14.929.055l.003 14.768-14.768.004z"
          fill="url(#missingRecord-c)"
          transform="translate(44.388 2.17)"
        />
        <path
          d="M70.847 76.045l6.254 6.254"
          stroke="url(#missingRecord-d)"
          strokeWidth="7"
          transform="translate(44.388 2.17)"
        />

        <g fill="url(#missingRecord-e)" transform="translate(117.35 80.177)">
          <path d="M.063 8.368l13.939 13.94a5.765 5.765 0 1 0 8.153-8.153L8.215.215.063 8.368z" />
        </g>

        <g fill="url(#missingRecord-f)" transform="translate(76.577 39.762)">
          <path d="M7.219 7.014c-9.294 9.295-9.294 24.364 0 33.659 9.295 9.295 24.365 9.295 33.659 0 9.295-9.295 9.295-24.364 0-33.659a23.724 23.724 0 0 0-16.83-6.97A23.727 23.727 0 0 0 7.22 7.013" />
        </g>
      </g>
    </SvgIcon>
  )
}

MissingRecord.propTypes = {
  style: PropTypes.object,
}

export default MissingRecord
